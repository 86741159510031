import React from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../../utils/useFetch';
  
const Articles = (props) => {
  // const articles = useFetch('http://localhost:8888/5dinteractive/wp-json/wp/v2/posts/');
  const articles = useFetch('https://www.5dinteractive.com/wp-json/wp/v2/posts/');
  return (
    <div id="primary" className="page-default">
      <section className="page-section">
        <div className="container clearfix">
          <div className="articles-list">
            <div className="row">
              {articles && articles.map((article, index) => {
                return (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <article id={"post-"+article.id} className="hover-box hover-box__sm hover-box__neon-blue">
                      <div className="item">
                        <div className="post-meta heading5">
                          <span className="overline-text">Category Name&nbsp;</span>
                          <span class="posted-on">Post date</span>
                        </div>
                        <h3 className="heading4"><Link to={"/explore-5di/"+article.id}>{article.title.rendered}</Link></h3>
                        <Link to={"/explore-5di/"+article.id} class="posted-in heading5">Read More</Link>
                      </div>
                    </article>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Articles;