import React from 'react'
import useFetch from '../../utils/useFetch';


const Home = (props) => {
  // const posts = useFetch('http://localhost:8888/5dinteractive/wp-json/wp/v2/posts/?per_page=5');
  const homeContent = useFetch('http://localhost:8888/5dinteractive/wp-json/api/v2/home/');
  return (
    <div id="primary" className="page-default">
      {homeContent && homeContent.map((item, index) => {

        const banerStyle = {
          color: item.banner.backgroundColor,
          backgroundImage: 'url(' + item.banner.image + ')',
        };

        return(
          <article key={index} id={"post-"+item.id}>
            <section className="home-hero" style={banerStyle}>
              <div className="container clearfix" dangerouslySetInnerHTML={{__html: item.banner.content}}/>
            </section>
            <section className="page-section work-with-you">
              <div className="container clearfix">
                <div className="row">
                  <div className="col-xl-8 col-lg-7">
                    <h2 className="heading2" dangerouslySetInnerHTML={{__html: item.work_with_you.heading}} />
                    <ul className="clearfix">
                      {item.work_with_you.items && item.work_with_you.items.map((workItem, workIndex) => (
                        <li key={workIndex}>{workItem}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-xl-4 col-lg-5">
                    show contact form here...
                  </div>
                </div>
              </div>
            </section>


            <section className="page-section work">
              <div className="container clearfix">
                <h2 className="heading2" dangerouslySetInnerHTML={{__html: item.work_with_us.heading}} />
              </div>

              {item.work_with_us.items && item.work_with_us.items.map((workItem, workIndex) => {
                const figureStyle = {
                  backgroundImage: 'url(' + workItem.image_url + ')',
                };
                return (
                  <div className="work-block" key={workIndex}>
                    <div className="container">
                      <div className="block-content">
                        <div className="block-content-inner">
                          <h3 className="heading3" dangerouslySetInnerHTML={{__html: workItem.title}} />
                          <div dangerouslySetInnerHTML={{__html: workItem.content}} />
                          <a href="/" className="link-btn">Read More</a>
                        </div>
                    </div>
                  </div>
                  <figure style={figureStyle}></figure>
                </div>
                )
              })}
            </section>

            <section className="page-section technologies">
              <div className="container clearfix">
                <h2 className="heading2" dangerouslySetInnerHTML={{__html: item.technologies.heading}} />
                <div className="technology-list clearfix">
                  <div className="items clearfix">
                    {item.technologies.items && item.technologies.items.map((technologyItem, technologyIndex) => {
                      const figureStyle = {
                        backgroundImage: 'url(' + technologyItem.image_url + ')',
                      };
                      return (
                        <div className="item__wrapper" key={technologyIndex}>
                          <a href={technologyItem.link_url} className="item">
                            <figure style={figureStyle}></figure>
                            <div className="item__content">
                              <h3 className="item__title" dangerouslySetInnerHTML={{__html: technologyItem.title}} />
                              <div className="item__excerpt" dangerouslySetInnerHTML={{__html: technologyItem.content}} />
                            </div>
                          </a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </section>
          </article>
        )
      } )}
    </div>
  )
}

export default Home


// class Home extends Component {
  
//   render() {
//     return (
//       <div>
//         <h1>Home</h1>
//         <p>Lorem Ipsum</p>
//         <p>Lorem Ipsum</p>
//         <p>Lorem Ipsum</p>
//         <p>Lorem Ipsum</p>
//         <p>Lorem Ipsum</p>
//         <p>Lorem Ipsum</p>
//         <p>Lorem Ipsum</p>
//         <p>Lorem Ipsum</p>
//       </div>
//     )
//   }
// }

// export default Home;