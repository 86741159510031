import React from 'react';
import useFetch from '../../utils/useFetch';

const About = () => {
  const homeContent = useFetch('http://localhost:8888/5dinteractive/wp-json/api/v2/about/');
  return (
    <div id="primary" className="page-default">
      {homeContent && homeContent.map((item, index) => {

        const banerStyle = {
          color: item.banner.backgroundColor,
          backgroundImage: 'url(' + item.banner.image + ')',
        };

        const contentSytle = {
          backgroundColor: '#ffffff'
        }

        return(
          <article key={index} id={"post-"+item.id}>
            <section className="home-hero" style={banerStyle}>
              <div className="container clearfix" dangerouslySetInnerHTML={{__html: item.banner.content}}/>
            </section>

            <section className="page-section about-intro">
              <div className="container clearfix">
                <div className="entry-content">
                  <div className="wp-block-columns are-vertically-aligned-top home-columns-content">
                    <div className="wp-block-column is-vertically-aligned-top">
                      <p>Experience new learning, entertainment, culture, health and work in a fundamentally different way.</p>
                    </div>
                    <div className="wp-block-column is-vertically-aligned-top">
                      <p>Engage with unique phygital experiences and much more</p>
                    </div>
                    <div className="wp-block-column is-vertically-aligned-center">
                      <p>Learn the skills of the creative economy and be a part of the green jobs revolution</p>
                    </div>
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="col-lg-10 col-md-12 col-sm-12 offset-lg-1 offset-md-0">
                    <div className="entry-content"><p>Photogrammetry, Digital Humans, Performance Capture, Volumetric Capture are simply not technology buzzwords for the sake of it. Find out how they can help your business in a meaningful way</p></div>    
                  </div>
                </div>
              </div>
            </section>
            
            <section className="page-section" style={contentSytle}>
              <div className="container clearfix">
                <div className="row">
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div className="entry-content" dangerouslySetInnerHTML={{__html: item.content}}></div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    contact form will be here...
                  </div>
                </div>
              </div>
            </section>
          </article>
        )
      } )}
    </div>
  )
}

export default About;