import React from 'react';
import { NavLink } from 'react-router-dom';

const MainNavigation = (props) => {
  const menuItems = [
    {
      text: 'Home',
      link: '/',
      class: 'home'
    },
    {
      text: 'About',
      link: '/about',
      class: 'about'
    },
    {
      text: 'User Form',
      link: '/user-form',
      class: 'user-form'
    },
    {
      text: 'Explore 5DI',
      link: '/explore-5di',
      class: 'explore-5di'
    },
  ]

  // const closeMenu = () => {
  //   console.log('close menu');
  //   // props.handleClose();
  // }

  const navItems = () => {
    return menuItems.map( (item, i) => (
      <li key={i} className={"menu-item-"+item.class}>
        <NavLink 
          to={item.link} 
          activeClassName="current-menu-item"
          onClick={() => props.handleClose()}
          >
          {item.text}
          
        </NavLink>
      </li>
    ) )
  }
  console.log('MyProp: '+props.abc);

  return (
    <div className={"mobile-navigation " + ((props.abc) ? 'open': '')}>
      
      <button 
        className="menu-close"
        onClick={() => props.handleClose()}
        >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100px" height="100px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>
      </button>
      <div className="menu-container clearfix">
        <ul id="primary-menu">
          {navItems()}
        </ul>
      </div>
    </div>
  )
}

export default MainNavigation;