import React, { Component } from 'react';

import { Route, Switch } from 'react-router-dom';
import Layout from './hoc/Layout/layout';
import Home from './components/Home/home';
import About from './components/About/about';
import Articles from './components/Explore5DI';
import Article from './components/Explore5DI/article';
import UserForm from './components/UserForm/userForm';

class Routes extends Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route path="/explore-5di" exact component={Articles} />
          <Route path="/explore-5di/:id" component={Article} />
          <Route path='/about' exact component={About}/>
          <Route path='/user-form' exact component={UserForm}/>
          <Route path='/' exact component={Home}/>
        </Switch>
      </Layout>
    )
  }
}

export default Routes;