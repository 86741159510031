import React, { useState } from 'react';

const UserForm = (props) => {
  const [user, setUser] = useState({
    name: 'Kapil Jain',
    email: 'kapilj@futurescape.co'
  })

  const submit = e => {
    e.preventDefault()
    // const data = new FormData(form.current)
    fetch('http://localhost:8888/5dinteractive/wp-json/api/v2/userform/', {
      method: 'POST',
      // body: JSON.stringify({ user }),
      body: JSON.stringify({ title: 'React POST Request Example' }),
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.json())
    .then(json => setUser(json.user))
  }

  return (
    <form onSubmit={submit}>
      <input
        type="text"
        name="user[name]"
        placeholder="User name"
        value={user.name}
        onChange={e => setUser({ ...user, name: e.target.value })}
      />
      {/* {user.errors.name && <p>{user.errors.name}</p>} */}

      <input
        type="email"
        name="user[email]"
        placeholder="Email address"
        value={user.email}
        onChange={e => setUser({ ...user, email: e.target.value })}
      />
      {/* {user.errors.name && <p>{user.errors.name}</p>} */}

      <input type="submit" name="Sign Up" />
    </form>
  )
}

export default UserForm;