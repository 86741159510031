import React, { Component } from 'react'

// import { Link }  from 'react-router-dom';
import logoFuturescapeImage from '../../img/logo-futurescape.png';

class Footer extends Component {
  render() {
    const logoFuturescape = () => {
      return (
          <img src={logoFuturescapeImage} alt="Futurescape" />
      )
  }

    return (
      <footer id="site-footer" role="contentinfo">
        <div className="container clearfix">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="footer-navigation">
                <div className="row">
                  <div className="col-md-4 col-sm-12"></div>
                  <div className="col-md-4 col-sm-12"></div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="social-links">
                <a href="https://twitter.com/Explore5DI" className="twitter" target="_blank" rel="noreferrer">Twitter</a>
                <a href="https://www.linkedin.com/company/futurescape" className="linkedin" target="_blank" rel="noreferrer">LinkedIn</a>
                <a href="https://www.youtube.com/channel/UCte0KuI0aB6HNWP2bh9xXIA/" className="youtube" target="_blank" rel="noreferrer">YouTube</a>
              </div>
              
              <p className="copyright-text">&copy; 2021 <strong>5DI</strong>nter<strong>active</strong>. All rights reserved.</p>
              
              <div className="credits">
                <span>A Futurescape Division.</span>
                <a href="https://futurescape.in/" target="_blank" rel="noreferrer">{logoFuturescape()}</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

    )
  }
}

export default Footer;