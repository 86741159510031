import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../../utils/useFetch';

const Article = (props) => {
  let [article, setArticle] = useState(null);
  
  // const post = useFetch('http://localhost:8888/5dinteractive/wp-json/wp/v2/posts/'+props.match.params.id);
  const post = useFetch('https://www.5dinteractive.com/wp-json/wp/v2/posts/'+props.match.params.id);
  
  
  // useEffect(() => {
  //   const fetchArticle = async () => {
  //     // const response = useFetch('http://localhost:8888/5dinteractive/wp-json/wp/v2/posts/'+props.match.params.id);
  //       // const response = await axios.get(`${URL_BLOCKS}?id=${props.match.params.id}`)
  //     // setArticle(...response.data)
  //   }
  //   fetchArticle()
  // }, [props])

  
  // key={post.id} className={"article article-"+post.id}
  // alert(post.id);
  return (
    <section className="page-section">
    {
      post ?
        
          <div className="container clearfix">
            <div className="row">
              <div className="col-lg-8 col-md-7 col-sm-12">
                <article key={post.id} id={"post-"+post.id}>
                  <header className="entry-header">
                    <div className="postmeta heading4"><Link to="/explore-5di/">&lt; Back to Blog</Link> &nbsp;|&nbsp; Post Date</div>
                    <h1 className="entry-title">{post.title.rendered}</h1>
                  </header>
                  <div className="entry-content"  dangerouslySetInnerHTML={{__html: post.content.rendered}}/>
                </article>
              </div>
            </div>
          </div>
      :null
    }
    </section>
  )
}

export default Article;