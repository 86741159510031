import React, { useState } from 'react';

import MainNavigation from './Navigation/mainNavigation';

const Header = (props) => {

  const [state, updateState] = useState({
    isToggleOn: false
  })

  // constructor(props) {
  //   super(props);
  //   this.state = {isToggleOn: 'true'};
  //   this.handleClick = this.handleClick.bind(this);
  // }

  const handleClick = () => {
    updateState(prevState => {
      // console.log(e.type);
      console.log("aaa"+state.isToggleOn);
      return {
        ...prevState,
        isToggleOn: !state.isToggleOn
      }
    });
    return false;
  }

  return (
    <header id="site-header">
      <div className="container clearfix">
        <h1 className="site-title"><a href="/" title="5Dinteractive" rel="home"><strong>5DI</strong>nter<strong>active</strong></a></h1>
        <a className="skip-link screen-reader-text" href="#content">Skip to content</a>
        <button 
          id="menu-toggle"
          className="menu-toggle"
          onClick={(event) => handleClick()}
        ><span>Menu</span>
        </button>
          
        <MainNavigation abc={state.isToggleOn} handleClose ={() => handleClick()}/>
      </div>
    </header>
    // {(this.state.isToggleOn) ? 'true' : 'false'}
  )
}

export default Header;